import { faMousePointer } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { backendRequestAndNotification } from '../components/api/index.js'

import Campaigns from '../components/ptc/campaigns.jsx'
import Deposits from '../components/ptc/deposits.jsx'

const BuyPTC = ({ user, ws }) => {
    const [diamonds, setDiamonds] = useState('')
    const [deposit, setDeposit] = useState('')

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [url, setUrl] = useState('')
    const [type, setType] = useState('1')
    const [views, setViews] = useState('')
    const [cost, setCost] = useState(0)

    const [tab, setTab] = useState(0)

    function getMultiplier(type) {
        if (type === '1') return 1.5
        if (type === '2') return 2.5
        if (type === '3') return 4
        if (type === '4') return 10
    }

    function getTotalCost(type, views) {
        return (getMultiplier(type) * views)
    }

    async function submitCampaign() {
        const response = await backendRequestAndNotification('/user/campaigns', 'POST', { name, description, url, type, views })
        if(response.type === 'success') {
            setName('')
            setDescription('')
            setUrl('')
            setType('1')
            setViews('')
        }
    }

    async function createDeposit() {
        setTab(1)
        const response = await backendRequestAndNotification('/invoices/create', 'POST', { depositAmount: Number(deposit) })
        if(response.type === 'success') {
            setDeposit('')
            window.open(response.url, '_blank')
        }
    }

    useEffect(() => {
        if(views === 0) {
            setCost(0)
            return
        }

        setCost(getTotalCost(type, views))
    }, [type, views])

    return (
        <>
            <div className='ptc-container'>
                <div className='title'>
                    <span className='blue'><FontAwesomeIcon icon={faMousePointer} /></span>
                    <p>Paid-to-click (PTC)</p>
                </div>

                <div className='settings-container'>
                    <div className='settings-section'>
                        <p className='box-title'>Convert Diamonds</p>

                        <div className='box-inputs'>
                            <input placeholder='Amount of diamonds...' value={diamonds} onChange={(e) => setDiamonds(e.target.value)} type='number'/>
                            <button onClick={() => {
                                backendRequestAndNotification('/user/convert-points', 'POST', { amount: Number(diamonds) }) && setDiamonds('')
                            }}>Convert</button>
                        </div>
                        <p className='convert-text'>You will receive: <span>{diamonds === '' ? '-' : diamonds} Tokens</span></p>
                    </div>

                    <div className='settings-section'>
                        <p className='box-title'>Deposit Cryptocurrency</p>

                        {/* Deposits currently disabled */}
                        <p className='convert-text'>Deposits are currently disabled, please contact support if you want to buy ad tokens.</p>

                        {/* <div className='box-inputs'>
                            <input placeholder='Dollar amount...' value={deposit} onChange={(e) => setDeposit(e.target.value)} type="number" min="1" pattern="[0-9]" title="Numbers only"/>
                            <button onClick={() => {
                                createDeposit()
                            }}>Deposit</button>
                        </div>
                        <p className='convert-text'>You will receive: <span>{deposit === 0 ? '-' : (deposit*1000)} Tokens</span></p> */}
                    </div>
                </div>

                <div className='buy-container'>
                    <div className='buy-section'>
                        <div className='buy-title-flex'>
                            <p className='box-title'>Buy Campaign</p>

                            <p className='balance'>Your Ad Balance: <span style={{ color: '#f1c475' }}>{user?.adtoken_balance ? user.adtoken_balance : '0'} Tokens</span></p>
                        </div>

                        <div className='buy-inputs'>
                            <div className='lwi'>
                                <span>Name:</span>
                                <input placeholder='Enter campaign name...' value={name} onChange={(e) => setName(e.target.value)} maxLength={16}/>
                            </div>

                            <div className='lwi'>
                                <span>Description:</span>
                                <input placeholder='Enter campaign description...' value={description} onChange={(e) => setDescription(e.target.value)} maxLength={80}/>
                            </div>

                            <div className='lwi'>
                                <span>URL:</span>
                                <input placeholder='Enter campaign URL...' value={url} onChange={(e) => setUrl(e.target.value)} type='url'/>
                            </div>

                            <div className='lwi'>
                                <span>Duration:</span>
                                <select value={type} onChange={(e) => setType(e.target.value)}>
                                    <option value="1">5 seconds (1.5 tokens per view)</option>
                                    <option value="2">15 seconds (2.5 tokens per view)</option>
                                    <option value="3">30 seconds (4 tokens per view)</option>
                                    <option value="4">60 seconds (10 tokens per view)</option>
                                </select>
                            </div>


                            <div className='lwi'>
                                <span>Views:</span>
                                <input placeholder='Enter number of views...' value={views} onChange={(e) => setViews(e.target.value)} type='number'/>
                            </div>

                            <button onClick={() => {
                                submitCampaign()
                            }}>Buy</button>

                            <div style={{ backgroundColor: '#22294D', borderRadius: '6px', padding: '12px 10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <p style={{ fontWeight: 'bold', fontSize: '14px', margin: 'unset' }}>Total cost:</p>
                                <p style={{ color: '#f1c475', fontSize: '14px', fontWeight: 'bolder', margin: 'unset' }}>{cost} Ad Tokens</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='history-options'>
                    <div className={'option ' + (tab === 0 ? 'active' : '')} onClick={() => setTab(0)}>
                        <p>Campaigns</p>
                    </div>

                    <div className={'option ' + (tab === 1 ? 'active' : '')} onClick={() => setTab(1)}>
                        <p>Deposits</p>
                    </div>
                </div>

                {tab === 0 ? (
                    <Campaigns />
                ) : tab === 1 ? (
                    <Deposits ws={ws}/>
                ) : null}
            </div>

            <style jsx>{`
                .ptc-container {
                    width: 100%;
                    height: 100%;

                    display: flex;
                    flex-direction: column;

                    padding: 50px 200px;
                    gap: 25px;

                    overflow-x: hidden;
                    overflow-y: auto;
                }

                .ptc-container::-webkit-scrollbar {
                    display: none;
                }

                .buy-title-flex {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
                }

                .buy-title-flex .balance {
                    margin: unset;
                    font-size: 14px;
                }

                .box-title {
                    font-size: 20px;
                    margin: unset;
                }

                .convert-text {
                    margin: unset;
                    margin-top: -10px;
                    font-size: 14px;
                }

                .convert-text span {
                    color: #f1c475;
                }

                .box-inputs {
                    display: flex;
                    height: 45px;
                    width: 100%;
                    gap: 15px;
                }

                .buy-inputs .lwi {
                    display: flex;
                    flex-direction: column;
                }

                .buy-inputs .lwi span {
                    font-size: 14px;
                    margin-bottom: 4px;
                }

                .title {
                    font-weight: 500;
                    font-size: 24px;

                    gap: 10px;
                    display: flex;
                    align-items: center;
                }

                .title p {
                    margin: unset;
                }

                .blue {
                    color: #4DAEFF;
                }

                .buy-container {
                    width: 100%;
                }

                .buy-section input {
                    border: unset;
                    outline: unset;
                    width: 100%;
                    color: white;
                    background-color: #21284C;
                    padding: 0px 10px;
                    border-radius: 6px;
                    height: 45px;
                }

                .buy-section button {
                    border: unset;
                    outline: unset;
                    height: 45px;
                    color: white;
                    background-color: #4DAEFF;
                    border-radius: 6px;
                    cursor: pointer;
                }

                .buy-section select {
                    background: #21284C;
                    border-radius: 6px;
                    outline: unset;
                    border: unset;
                    color: white;
                    height: 45px;
                    padding: 10px;
                    width: 100%;
                    text-indent: 5px;

                }

                .buy-section {
                    display: flex;
                    flex-flow: column;
                    align-items: flex-start;
                    flex: 1;
                    background-color: #141A31;
                    border-radius: 12px;
                    padding: 15px;
                    gap: 15px;
                }

                .buy-inputs {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                }

                .settings-container {
                    display: flex;

                    gap: 15px;
                    width: 100%;
                }

                .history-options {
                    height: 70px;
                    width: 100%;
                    padding: 15px;
                    border-radius: 12px;
                    background-color: #141A31;
                    display: flex;
                    gap: 15px;
                }

                .chat-options {
                    height: 200px;
                    width: 100%;
                    padding: 15px;
                    border-radius: 12px;
                    background-color: #141A31;
                    display: flex;
                    gap: 15px;
                }

                .option {
                    flex: 1;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    transition: background-color .3s;
                    border-radius: 6px;
                    color: rgb(156, 164, 191);
                    user-select: none;
                    text-align: center;
                }

                .active {
                    color: rgb(79, 141, 222);
                    background-color: rgb(28, 35, 68);
                    box-shadow: rgb(0 0 0 / 20%) 0px 3px 10px;
                }

                .option:hover {
                    background-color: #1E2A50;
                    color: white;
                }

                .settings-section {
                    display: flex;
                    flex-flow: column;
                    align-items: flex-start;
                    flex: 1;
                    background-color: #141A31;
                    border-radius: 12px;
                    padding: 15px;
                    gap: 15px;
                }

                .settings-section input {
                    border: unset;
                    outline: unset;
                    width: 100%;
                    color: white;
                    background-color: #21284C;
                    padding: 0px 10px;
                    border-radius: 6px;
                }

                .settings-section button {
                    border: unset;
                    outline: unset;
                    width: 150px;
                    color: white;
                    background-color: #4DAEFF;
                    border-radius: 6px;
                    cursor: pointer;
                }

                .top-section {
                    display: flex;
                    flex: 1;
                    height: 200px;
                    background-color: #141A31;
                    border-radius: 12px;
                    padding: 15px;
                    gap: 15px;
                }

                .column {
                    flex-direction: column;
                }

                .avatar {
                    border-radius: 8px;
                }

                .info-title {
                    margin: unset;
                    color: #F1C475;
                    font-size: 20px;
                    font-weight: 600;
                }

                .center {
                    text-align: center;
                }

                .right-container {
                    width: 100%;
                    height: 100%;

                    display: flex;
                    flex-direction: column;
                }

                .info-container {
                    display: flex;
                    height: 100%;
                    flex-direction: column;
                    justify-content: space-evenly;
                }

                .info {
                    width: 100%;
                    height: 20px;

                    font-weight: 600;
                    font-size: 13px;

                    display: flex;
                }

                .info p {
                    margin: unset;
                }

                .right {
                    margin-left: auto !important;
                }

                @media only screen and (max-width: 1700px) {
                    .ptc-container {
                        padding: 50px 100px;
                    }
                }

                @media only screen and (max-width: 1650px) {
                    .ptc-container {
                        padding: 50px;
                    }
                }

                @media only screen and (max-width: 1550px) {
                    .ptc-container {
                        padding: 50px 25px;
                    }
                }

                @media only screen and (max-width: 1500px) {
                    .top-container {
                        flex-direction: column;
                    }

                    .ptc-container {
                        padding: 50px 0px;
                    }
                }

                @media only screen and (max-width: 625px) {
                    .settings-container {
                        flex-direction: column;
                    }

                    .settings-section {
                        min-height: 75px;
                    }

                    .top-section {
                        height: auto;
                        flex-direction: column;
                    }

                    .top-section:not(.column) {
                        align-items: center;
                    }

                    .avatar {
                        width: 170px;
                    }
                    .qr-code-icon {
                        cursor: pointer;
                      }
                      
                      .qr-code-modal {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: fixed;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0, 0, 0, 0.5);
                        z-index: 1000;
                      }
                      
                      .modal-content {
                        background-color: white;
                        border-radius: 8px;
                        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                        text-align: center;
                        padding: 20px;
                      }
                      
                      .modal-header {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 10px;
                      }
                      
                      .modal-header h2 {
                        margin: 0;
                      }
                      
                      .modal-header button {
                        background-color: transparent;
                        border: none;
                        cursor: pointer;
                        font-size: 24px;
                      }
                      
                      .modal-body {
                        margin-top: 10px;
                      }
                }
            `}</style>
        </>
    )
}

export default BuyPTC