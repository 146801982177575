import { faGem } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { backendRequestAndNotification } from '../../api/index'

const Solana = ({img}) => {

    const [amount, setAmount] = useState(0)
    const [address, setAddress] = useState('')

    return (
        <>
            <div className='cashout-container'>
                <div className='information'>
                    <div className='image-container'>
                        <img src={img} alt='' draggable='false'/>
                    </div>
                    
                    <p><span className='blue'>Fee:</span> <span className='fee'>0 <FontAwesomeIcon icon={faGem}/></span></p>
                </div>

                <div className='inputs'>
                    <div className='input'>
                        <p className='label'>You <span className='blue'>Withdraw</span></p>
                        <input placeholder='Withdraw Amount' value={amount} onChange={(e) => setAmount(e.target.value)}/>
                    </div>

                    <div className='input'>
                        <p className='label'>Your <span className='blue'>SOL Address</span></p>
                        <input placeholder='SOL Address' value={address} onChange={(e) => setAddress(e.target.value)}/>
                    </div>

                    <div className='horz'>
                        <p className='blue'>Minimum withdrawal amount is 400 Diamonds ($0.40)</p>
                        <div className='purchase' onClick={() => {
                            backendRequestAndNotification(`/withdraw/sol`, 'POST', { address, amount: parseInt(amount) })
                        }}>
                            <p>Purchase</p>
                        </div>
                    </div>
                </div>
            </div>

            <style jsx>
                {`
                    .cashout-container {
                        height: 250px;
                        min-height: 250px;
                        width: 100%;
                        background-color: rgb(20, 26, 49);
                        border-radius: 8px;
                        padding: 15px;
                        font-weight: 600;

                        display: flex;
                        gap: 15px;

                        overflow: hidden;
                    }

                    .information {
                        width: 280px;
                        height: 100%;

                        display: flex;
                        flex-direction: column;
                    }

                    .information p {
                        margin: auto 0;
                        font-weight: 600;
                        display: flex;
                    }

                    .fee {
                        margin-left: auto;
                    }

                    .image-container {
                        height: 140px;
                        width: 280px;
                        background: linear-gradient(360deg, #DA20FE 0%, #08F5A7 100%);
                        border-radius: 8px;

                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    img {
                        width: 140px;
                        user-select: none;
                    }

                    .blue {
                        color: #4DAEFF;
                    }

                    .input {
                        width: 100%;
                    }

                    .inputs {
                        width: 100%;
                        height: 100%;

                        display: flex;
                        flex-direction: column;
                    }

                    .label {
                        margin: 0px 0px 5px 0px;
                    }

                    .input input {
                        width: 100%;
                        height: 40px;
                        padding: 0px 15px;
                        border-radius: 6px;
                        outline: unset;
                        border: unset;
                        font-size: 14px;
                        background-color: #21284C;
                        color: white;
                        margin-bottom: 10px;
                    }

                    .horz {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        overflow: hidden;
                    }

                    .purchase {
                        margin-left: auto;
                        height: 50px;
                        width: 170px;
                        background-color: #22294D;
                        border-radius: 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #629CF1;
                        transition: all .2s;
                        cursor: pointer;
                    }

                    .purchase:hover {
                        background-color: #2B3D6D
                    }

                    @media only screen and (max-width: 950px) {
                        .cashout-container {
                            flex-direction: column;
                            min-height: unset;
                            height: fit-content;
                            align-items: center;
                            gap: 25px;
                        }

                        .information {
                            height: fit-content;
                        }

                        .inputs {
                            height: fit-content;
                        }
                    }

                    @media only screen and (max-width: 625px) {
                        .horz {
                            flex-direction: column;
                            align-items: center;
                        }

                        .purchase {
                            width: 100%;
                        }
                    }
                `}
            </style>
        </>
    )
}

export default Solana