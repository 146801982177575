import { backendRequestAndNotification, createNotification } from '../api'
import { faGem } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import YouTubePlayer from './rickroll'

const ExclusiveModal = ({ user, setShow }) => {
    const handleOffer = (e) => {
        const offerElement = e.target.closest('.exclusive-offer');
        if (!offerElement) return;

        const trackingId = user?.offer_id;
        
        if(offerElement.id === 'rustclash') {
            const newWindow = window.open(`https://trk.rustclash.com/click?o=3&a=119&c=90&sub_id1=${trackingId}`, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null;
        }

        if(offerElement.id === 'casesgg') {
            const newWindow = window.open(`https://trk.cases.gg/click?o=1&a=119&c=88&sub_id1=${trackingId}`, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null;
        }

        if(offerElement.id === 'clash') {
            const newWindow = window.open(`https://trk.clash.gg/click?o=2&a=119&c=89&sub_id1=${trackingId}`, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null;
        }
    }

    return (
        <>
            <div className='exclusive-modal' onClick={(e) => { setShow(false) }}>
                <div className='exclusive-container' onClick={(e) => e.stopPropagation()}>
                    <p className='close' onClick={(e) => { setShow(false) }}>x</p>

                    <p className='title gold'>GrindBux Exclusive</p>

                    <div id='rustclash' className='exclusive-offer' onClick={handleOffer}>
                        <div className='exclusive-left'>
                            <img src="/offers/rustclash.png" alt="exclusive" />
                            <div className='exclusive-left-text'>
                                <p className='title'>RustClash</p>
                                <p className='description'>Create a new account and deposit $5 USD</p>
                            </div>
                        </div>

                        <div className='exclusive-right'>
                            <div className='reward'>7000 <FontAwesomeIcon icon={faGem}/></div>
                        </div>
                    </div>

                    <div id='casesgg' className='exclusive-offer' onClick={handleOffer}>
                        <div className='exclusive-left'>
                            <img src="/offers/casesgg.png" alt="exclusive" />
                            <div className='exclusive-left-text'>
                                <p className='title'>CasesGG</p>
                                <p className='description'>Create a new account and deposit $5 USD</p>
                            </div>
                        </div>

                        <div className='exclusive-right'>
                            <div className='reward'>7000 <FontAwesomeIcon icon={faGem}/></div>
                        </div>
                    </div>

                    <div id='clash' className='exclusive-offer' onClick={handleOffer}>
                        <div className='exclusive-left'>
                            <img src="/offers/clash.png" alt="exclusive" />
                            <div className='exclusive-left-text'>
                                <p className='title'>ClashGG</p>
                                <p className='description'>Create a new account and deposit $5 USD</p>
                            </div>
                        </div>

                        <div className='exclusive-right'>
                            <div className='reward'>7000 <FontAwesomeIcon icon={faGem}/></div>
                        </div>
                    </div>
                </div>
            </div>

            <style jsx>
                {`
                    .exclusive-modal {
                        position: fixed;
                        height: 100%;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: rgba(0,0,0,0.3);
                        z-index: 1001;
                        top: 0;
                        left: 0;
                    }

                    .title {
                        font-size: 16px;
                        font-weight: 500;
                    }

                    .gold {
                        color: #F1C475;
                    }

                    .exclusive-container {
                        position: relative;
                        height: fit-content;
                        width: 600px;
                        background: #21294D;
                        border-radius: 8px;

                        position: relative;

                        display: flex;
                        flex-direction: column;

                        padding: 24px;
                        box-sizing: border-box;
                        gap: 12px;

                        overflow: hidden;
                    }

                    .close {
                        font-weight: 700;
                        font-size: 12px;
                        position: absolute;
                        top: 8px;
                        right: 8px;
                        cursor: pointer;
                    }


                    .exclusive-offer {
                        padding: 6px 8px;
                        border-radius: 4px;
                        background-color: #2A335B;

                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        cursor: pointer;
                    }

                    .exclusive-left {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                    }
                    
                    .exclusive-left-text .description {
                        font-size: 14px;
                        color: #999;
                    }

                    .exclusive-left-text .title {
                        font-size: 16px;
                        font-weight: 600;
                    }

                    .exclusive-left img {
                        height: 5rem;
                        width: 5rem;
                        border-radius: 4px;
                    }

                    .exclusive-right .reward {
                        font-size: 14px;
                        font-weight: 600;
                        color: #F1C475;
                        padding: 4px 8px;
                        background-color: #4D3A4B;
                        border-radius: 4px;
                    }

                    p {
                        margin: unset;
                    }
                `}
            </style>
        </>
    )
}

export default ExclusiveModal